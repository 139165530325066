<template>
  <div>
    <div
      style="
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-block: 1rem;
        gap: 1rem;
        align-items: center;
      "
    >
      <el-input
        placeholder="Search Resellers..."
        class="search_item"
        style="height: 40px !important; max-width: 20rem"
        v-model="search"
        :debounce="700"
        @input="debounceSearch"
        autocomplete="on"
        clearable
        @clear="getResellers(1, '')"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>

    <el-table :data="resellerUsers.data" height="55vh" v-loading="isLoading">
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column label="Email" prop="email" width="250"></el-table-column>
      <el-table-column label="Phone" prop="phone"></el-table-column>
      <el-table-column label="Host Domain" prop="host_domain"></el-table-column>
      <el-table-column
        label="White Listed"
        :filters="[{ text: 'Whitlisted Users', value: 1 }]"
        :filter-method="filterWhitelist"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag size="small" type="danger" v-if="scope.row.fraud_status"
            >{{ scope.row.fraud_status ? "White listed" : "" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Is Reseller Admin" prop="is_admin">
        <template slot-scope="scope">
          <el-tag size="small" type="warning"
            >{{ scope.row.is_admin ? "Yes" : "No" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
            :type="scope.row.fraud_status ? 'danger' : 'primary'"
            size="mini"
            @click="showToggleConfirmation(scope.row)"
          >
            {{
              scope.row.fraud_status
                ? "Remove from whitelist"
                : "Add to whitelist"
            }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="resellerUsers.per_page"
        :total="resellerUsers.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="confirmationVisible"
      width="45%"
      center
      :title="
        !selectedUser.fraud_status
          ? 'Add to Whitelist'
          : 'Remove from Whitelist'
      "
      @closed="selectedUser = {}"
    >
      <div style="text-align: center; margin-bottom: 1rem">
        <p>
          {{
            !selectedUser.fraud_status
              ? "Are you sure you want to add this user to the whitelist?"
              : "Are you sure you want to remove this user from the whitelist?"
          }}
        </p>
        <el-divider></el-divider>
        <p style="display: flex; justify-content: space-between">
          <strong>Name:</strong> {{ selectedUser.name }}
        </p>
        <p style="display: flex; justify-content: space-between">
          <strong>Email:</strong> {{ selectedUser.email }}
        </p>
        <p style="display: flex; justify-content: space-between">
          <strong>Phone:</strong> {{ selectedUser.phone }}
        </p>
        <p style="display: flex; justify-content: space-between">
          <strong>Host Domain:</strong> {{ selectedUser.host_domain }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmationVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="handleConfirmToggle"
          :loading="confirmationLoading"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "allUsers",
  data() {
    return {
      search: "",
      isLoading: false,
      confirmationVisible: false,
      selectedUser: {
        host_domain: "",
        user_id: "",
        fraud_status: "",
      },
      confirmationLoading: false,
    };
  },
  computed: {
    resellerUsers() {
      return this.$store.getters["admin/reseller/resellerUsers"];
    },
  },
  methods: {
    filterWhitelist(value, row) {
      return row.fraud_status === value;
    },
    handleCurrentChange(val) {
      this.getResellers(val, this.search);
    },
    getResellers(page = 1, search = "") {
      this.isLoading = true;
      this.$store
        .dispatch("admin/reseller/getResellerUsers", {
          page: page,
          search: search,
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceSearch: _.debounce(function () {
      this.getResellers(1, this.search);
    }, 700),

    showToggleConfirmation(row) {
      this.selectedUser = row;
      this.confirmationVisible = true;
    },
    handleConfirmToggle() {
      this.confirmationLoading = true;
      this.$store
        .dispatch("admin/fraudSystem/toggleResellerFraudWhitelist", {
          user_id: this.selectedUser.id,
          fraud_ignore_status: Number(!this.selectedUser.fraud_status),
          host_domain: this.selectedUser.host_domain,
        })
        .then(() => {
          this.confirmationVisible = false;
          this.$message.success(
            !this.selectedUser.fraud_status
              ? "User added to whitelist successfully"
              : "User removed from whitelist successfully"
          );
          this.getResellers(this.resellerUsers.current_page, this.search);
        })
        .finally(() => {
          this.confirmationLoading = false;
        });
    },
  },
  created() {
    this.getResellers(1, "");
  },
};
</script>

<style scoped></style>
